import React, { Component } from 'react'
import Header from './Header'
import { withRouter } from 'react-router-dom';
import animation from '../assets/animation.gif'

class Trade extends Component {
    render() {
        return (
            <div>
                <Header currentpage={this.props.location.pathname} />

                <div className="farms_main_container">
                    <div className="farms_main_area">
                        <div className="farms_main_flex">
                            {/* <FarmsCard />
                            <FarmsCard />
                            <FarmsCard /> */}
                            <div className="farms_not_created">
                                <div className="farms_not_created_inner">
                                    <div><img src={animation}></img></div>
                                    <div>   Not Listed in Exchages Yet !!!</div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(Trade)