import React, { Component } from 'react'
import "./Lands.css"
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

import links from '../assets/linkb.svg'
import arrow from '../assets/arrow_w.svg'
import play from '../assets/play.svg'
import bull from '../assets/bull.svg'
import salt from '../assets/SALT.svg'
import waves from '../assets/hero_waves.png'
import logo from '../assets/logo.svg'
import square from '../assets/marquee_square.svg'
import wavs_about from '../assets/waves_about.png'
import logoball from '../assets/logoball.svg'
import konst_bg from '../assets/konst_bg.svg'
import stone from '../assets/stone.svg'
import tokonomics from '../assets/tokonomics.svg'
import plus from '../assets/plus.svg'
import doll_l from '../assets/doll_l.svg'
import doll_r from '../assets/doll_r.svg'

import { motion } from 'framer-motion'

export default class Lands extends Component {

    state = {
        pointer1:false,
        pointer2:false,
        pointer3:false,
        pointer4:false
    }

    setpointer1(){
        this.setState({pointer1:!this.state.pointer1})
    }
    setpointer2() {
        this.setState({ pointer2: !this.state.pointer2 })
    }
    setpointer3() {
        this.setState({ pointer3: !this.state.pointer3 })
    }
    setpointer4() {
        this.setState({ pointer4: !this.state.pointer4 })
    }


    render() {
        return (
            <div className="lands_top">
            <div className="lands_main">
                <div className="lands_header_main">
                        <img src={logo} className="lands_header_logo" />
                        <Link to="/dashboard">    <div className="lands_header_links_item">
                            <div>Open App</div>
                            <div><img src={links} className="lands_header_icons" /></div>
                        </div> </Link>
                </div>
                <div className="lands_hero_main">
                    <div className="lands_hero_flex">
                        <div className="lands_hero_left">
                            <div className="lands_hero_title">KONSTANT</div>
                            <div className="lands_hero_title">PROTOCOL</div>
                            <div className="lands_hero_left_links">
                                    <Link to={{ pathname: "https://docs.konstant.tech" }} target="_blank" >       <div className="lands_hero_left_links_item">
                                    <div>Learn more</div>
                                    <img src={links} className="lands_hero_icons"/>
                                </div> </Link>
                                    <Link to={{ pathname: "https://youtu.be/k3YKvgojiZM" }} target="_blank" >  <div className="lands_hero_left_links_item">
                                    <div>Watch Reel</div>
                                    <img src={play} className="lands_hero_icons" />
                                </div></Link>
                            </div>
                        </div>
                        <div className="lands_hero_right">
                            <div className="lands_hero_right_div">
                                    <motion.img initial={{ rotate: -10 }} animate={{ rotate: 10 }} transition={{ delay: 1, duration: 2, yoyo: Infinity }} src={bull} className="lands_hero_bull" />
                                    <motion.img initial={{ y: -30 }} animate={{ y: 40 }} transition={{ delay: 1, duration: 2,yoyo:Infinity }} src={salt} className="lands_hero_salt" />
                            </div>

                        </div>
                            <img src={waves} className="lands_hero_waves" />
                    </div>

                </div>

                <div>
                        <hr></hr>
                        <Marquee gradient={false} speed={100}>
                            <div className="lands_marquee_flex">
                                <div>STABLECOIN OF THE FUTURE</div>
                                <img src={square} className="land_marquee_icon" />
                                <div>STABLECOIN OF THE FUTURE</div>
                                <img src={square} className="land_marquee_icon" />
                                <div>STABLECOIN OF THE FUTURE</div>
                                <img src={square} className="land_marquee_icon" />
                                <div>STABLECOIN OF THE FUTURE</div>
                                <img src={square} className="land_marquee_icon" />
                            </div>

                        </Marquee>
                        <hr></hr>
                </div>
                <div className="lands_about_us_main">
                        <motion.img initial={{ y: -30 }} animate={{ y: 40 }} transition={{ delay: 1, duration: 2, yoyo: Infinity }} src={logoball} className="land_about_us_logo" />
                        <div>
                            <div className="land_about_us_title">ABOUT</div>
                            <div className="land_about_us_title">KUSD</div>
                            <div className="land_about_us_content">KUSD is an algorithmic stablecoin with an inherant twist in its supply mechanics. Its supply changes everyday (Pro-Rata). KUSD is much more reactive to demand and supply.
                                It is a financial building-block much like Bitcoin, it is algorithmic and uncollateralized. However unlike Bitcoin, KUSD can be used as a stablecoin.
                            </div>
                            <Link to={{ pathname: "https://bscscan.com/token/0x173F16f6DeBA17cfacFc4C9DC8F804C547B18c12" }} target="_blank" >  <div className="land_about_us_content_item">
                                <div>View Contract</div>
                                <div><img src={links} className="lands_header_icons" /></div>
                            </div> </Link>
                        </div>
                        <img src={wavs_about} className="land_about_us_waves" />
                </div>

                <div className="lands_pointers_main">
                    <div className="lands_pointers_item_white">
                        <div className="lands_pointers_left">01</div>
                            <div className="lands_pointers_right">Fiat collateralized stablecoins such as (USDT, USDC) rely on centralized banking partnerships.</div>
                    </div>

                    <div className="lands_pointers_item_black">
                        <div className="lands_pointers_left">02</div>
                            <div className="lands_pointers_right">KUSD is an independent financial primitive that does not rely on centralized collateral or lenders of last resort. </div>
                    </div>

                    <div className="lands_pointers_item_white">
                        <div className="lands_pointers_left">03</div>
                            <div className="lands_pointers_right">Debt-marketplace derived stablecoins such as (DAI) cannot be sustained by free market incentives and rely on periodic bailouts.
                        </div>
                    </div>

                </div>

                <div className="land_how_it_works_main">
                        <div className="land_how_it_works_left">
                            <img src={konst_bg} className="land_how_it_works_bg" />
                            <motion.img initial={{ y: -40 }} animate={{ y: 30 }} transition={{ delay: 1, duration: 2, yoyo: Infinity }} src={stone} className="land_how_it_works_stone" />
                        </div>
                        <div className="land_how_it_works_right">
                            <div className="land_about_us_title">HOW IT</div>
                            <div className="land_about_us_title">WORKS</div>
                            <div className="land_about_us_content">The Konstant protocol translates price-volatility into supply-volatility.
                                This means the number of KUSD tokens in user wallets automatically
                                increases or decreases based on price.
                                    <br></br><br></br>
                                These supply adjustments are called "Redemptions" and they
                                occur once each day. When the KUSD network grows you'll
                                automatically have more tokens in your wallet, when the Konstant
                                network shrinks you'll automatically have fewer tokens in your wallet,
                                but the price per KUSD will tend to cycle around $1. This novel
                                redemption mechanism is what allows KUSD to be used as a stablecoin.
                            </div>
                            <Link to={{ pathname: "https://docs.konstant.tech" }} target="_blank" >   <div className="land_about_us_content_item">
                                <div>Learn More</div>
                                <div><img src={links} className="lands_header_icons" /></div>
                            </div> </Link>
                        </div>
                    
                </div>

                <div className="land_tokonomics_main">
                        <div className="land_tokonomics_title">TOKEN</div>
                        <div className="land_tokonomics_title">ECONOMICS</div>
                        <div className="land_tokonomics_flex">
                            <div className="land_tokonomics_left">
                                <div className="land_tokonomics_left_item">
                                    <div>Initial Total Supply</div>
                                    <div>12,000,000</div>
                                </div>
                                <div className="land_tokonomics_left_item">
                                    <div>Initial KUSD Price</div>
                                    <div>$ 0.06</div>
                                </div>
                            </div>
                            <div>
                                <img src={tokonomics} className="lands_tokonomics_icon" />
                            </div>
                        </div>
                </div>

                <div className="lands_pointers2_main">
                        <div className="lands_pointers2_title">POINTERS</div>
                        <hr></hr>
                        <div className="lands_pointers2_item_flex" onClick={()=> this.setpointer1()}>
                            <div className="lands_pointers2_item_title">The number of KUSD you own can change each day</div>
                            <img src={plus} className="lands_pointers2_icon" />
                        </div>
                        {this.state.pointer1 && <div className="lands_pointers2_item_content">
                            The Konstant protocol automatically adjusts supply in response to demand. When price is high, wallet balances increase. When price is low, wallet balances decrease.
                        </div>}
                        <hr></hr>
                        <div className="lands_pointers2_item_flex" onClick={() => this.setpointer2()}>
                            <div className="lands_pointers2_item_title">You can never be diluted by supply inflation</div>
                            <img src={plus} className="lands_pointers2_icon" />
                        </div>
                        {this.state.pointer2 &&  <div className="lands_pointers2_item_content">
                            KUSD is non-dilutive. Supply adjustments are applied universally and proportionally across every wallet’s balance. This means your percent ownership of the network remains fixed.
                        </div> }
                        <hr></hr>
                        <div className="lands_pointers2_item_flex" onClick={() => this.setpointer3()}>
                            <div className="lands_pointers2_item_title">This Solves The Diversification Problem</div>
                            <img src={plus} className="lands_pointers2_icon" />
                        </div>
                        {this.state.pointer3 &&  <div className="lands_pointers2_item_content">
Today's cryptocurrenices are dangerously correlated. Konstant's unique incentives allow it to decouple from Bitcoin's price pattern. This reduces systematic risk by adding diversity to a homogeneous ecosystem.                        
</div>}
                        <hr></hr>
                        <div className="lands_pointers2_item_flex" onClick={() => this.setpointer4()}>
                            <div className="lands_pointers2_item_title">This Solves The Inelasticity Problem</div>
                            <img src={plus} className="lands_pointers2_icon" />
                        </div>
                        {this.state.pointer4 &&   <div className="lands_pointers2_item_content">
                            Like precious metals, today's fixed supply cryptocurrencies are vulnerable to sudden shocks in demand and cannot be used to denominate complex contracts. As a result, sophisticated economies cannot be built upon them. KUSD is the simplest direct solution to the supply inelasticity problem.
                        </div> }
                        <hr></hr>
                </div>

                {/* <div className="lands_konst_main">
                    <div className="lands_konst_title">KONST</div>
                    <div className="lands_konst_content">KONST is a new governance token that completes the Konstant ecosystem by putting control of the protocol in the hands of the community. Using the KONST token, holders will be able to vote on changes to the protocol. Together, $KUSD + $KONST mark a major milestone in the protocol’s journey towards becoming fully decentralized.</div>
                </div> */}

                <div className="lands_footer_main">
                    <div className="lands_footer_flex">
                            <img src={doll_l} className="lands_footer_icon" />
                            <div>
                                <div className="lands_footer_content">IF YOU ARE CONVINCED KONSTANT
                                    STABLECOIN IS WORTH ITS VALUE
                                </div>
                                <div className="lands_footer_content_icon">
                                    <div>Buy Now</div>
                                    <div><img src={arrow} className="lands_footer_content_icon_right" /></div>
                                </div>
                            </div>
                           
                            <img src={doll_r} className="lands_footer_icon" />
                    </div>

                </div>

                    <div className="lands_sec_8">
                        <div className="lands_sec_8_inner">
                            <Link to={{ pathname: "https://docs.konstant.tech" }} target="_blank" >      <div>Documentation</div> </Link>
                            <Link to={{ pathname: "https://twitter.com/KonstantProtoc2" }} target="_blank" > <div>Twitter</div></Link>
                            <Link to={{ pathname: "https://discord.gg/9GF4h25yb5" }} target="_blank" > <div>Discord</div> </Link>
                            <Link to={{ pathname: "https://t.me/konstantprotocol" }} target="_blank" ><div>Telegram</div></Link>
                            <Link to={{ pathname: "https://konstantprotocol.medium.com/" }} target="_blank" >   <div>Medium</div> </Link>
                            <Link to={{ pathname: "https://github.com/Konstprotocol" }} target="_blank" >  <div>Github</div> </Link>
                        </div>
                    </div>
               
            </div>
        </div>
        )
    }
}
