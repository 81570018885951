import React, { Component } from 'react'
import './Header.css'
import logo from '../assets/logo.svg'
import menuicon from '../assets/menu.svg'
import close from '../assets/close.svg'
import {motion, AnimatePresence} from 'framer-motion'
import {Link} from "react-router-dom";

export default class Header extends Component {

    state={
        menucontrol:false
    }


    render() {
        return (
            <div className="header_main">
                <div className="header_container">
                    <div className="header_logo_container">
                        <img src={menuicon} className="header_menu_icon" onClick={()=> this.setState({menucontrol:true})}/>
                        <img src={logo} className="header_logo" />
                        <div className="header_logo_text">Konstant</div>
                    </div>

                    <div className="header_links_container">
                        <Link to="/dashboard"><div className={this.props.currentpage === "/dashboard" ? "header_links_item_selected" : "header_links_item"}>Dashboard</div></Link>
  
                        <Link to="/farms"><div className={this.props.currentpage === "/farms" ? "header_links_item_selected" : "header_links_item"}>Farms</div></Link>
                        <Link to="/trade"><div className={this.props.currentpage === "/trade" ? "header_links_item_selected" : "header_links_item"}>Trade</div></Link>
                        {/* <div className="header_links_item">Govern</div> */}
                    </div>

                    <div className="header_connect_button_container">
                        <div className="header_connect_button">Connect Wallet</div>
                    </div>

                </div>
                <AnimatePresence>
                {this.state.menucontrol ?
                    <motion.div className="header_menu_container"
                        initial={{ height: 0, opacity: 0.5 }} animate={{ height: '100vh', opacity: 1 }} exit={{height:0,opacity:0.5}} transition={{ duration: 0.5 }}>

                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }} exit={{ opacity: 0, transition: { delay: 0 } }}  className="header_menu_flex">
                            <div className="header_logo_menu_container">
                                <img src={logo} className="header_menu_logo" />
                            </div>
                            <div className="header_menu_item_container">
                                    <Link to="/dashboard">   <div className={this.props.currentpage === "/dashboard" ? "header_links_item_selected" : "header_links_item"}>Dashboard</div> </Link>
                                    <Link to="/farms"> <div className={this.props.currentpage === "/farms" ? "header_links_item_selected" : "header_links_item"}>Farms</div> </Link>
                                    <Link to="/trade"> <div className={this.props.currentpage === "/trade" ? "header_links_item_selected" : "header_links_item"}>Trade</div> </Link>
                                <div className="header_menu_item">Govern</div>
                            </div>
                            <div className="header_menu_close_container">
                                <div className="header_menu_close_flex"><img src={close} className="header_menu_close_logo" onClick={() => this.setState({ menucontrol: false })} /></div>
                            </div>
                        </motion.div>

                    </motion.div>
                 : null}
                </AnimatePresence>
            </div>
            
        )
    }
}
