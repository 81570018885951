import logo from './logo.svg';
import './App.css';

import Dashboard from './components/Dashboard'
import Farms from './components/Farms'
import Trade from './components/Trade'
import Land from './components/Land'
import Lands from './components/Lands'
import {motion} from 'framer-motion'

import bgwaves from './assets/bg_waves.svg'
import bgsphere from './assets/bg_sphere.svg'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
     
    
   
          <Switch>
        <Route exact path="/">
        <Lands/>
        </Route>
            <Route path="/dashboard">
          <div className="App">
            <div className="bgcontain">
              <motion.img src={bgwaves} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 0.2 }} className="bgwaves" />
              <motion.img src={bgsphere} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.5, duration: 0.5 }} className="bgsphere" />
            </div>
            <div className="content_container">
              <Dashboard />
            </div>
          </div>
            </Route>
            <Route path="/farms">
          <div className="App">
            <div className="bgcontain">
              <motion.img src={bgwaves} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 0.2 }} className="bgwaves" />
              <motion.img src={bgsphere} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.5, duration: 0.5 }} className="bgsphere" />
            </div>
            <div className="content_container">
              <Farms />
            </div>
          </div>
            </Route>
            <Route path="/trade">
          <div className="App">
            <div className="bgcontain">
              <motion.img src={bgwaves} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 0.2 }} className="bgwaves" />
              <motion.img src={bgsphere} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.5, duration: 0.5 }} className="bgsphere" />
            </div>
            <div className="content_container">
              <Trade />
            </div>
          </div>
            </Route>
          </Switch>
         
       
  

  
    </Router>
  );
}

export default App;
