import React, { Component } from 'react'
import Header from './Header'
import './Farms.css'
import { withRouter } from 'react-router-dom';
import FarmsCard from './FarmsCard'
import animation from '../assets/animation.gif'


class Farms extends Component {
    render() {
        console.log(this.props.location.pathname)
        return (
            <div>
                <Header currentpage={this.props.location.pathname}/>

                <div className="farms_main_container">
                    <div className="farms_main_area">
                        <div className="farms_main_flex">
                            {/* <FarmsCard />
                            <FarmsCard />
                            <FarmsCard /> */}
                            <div className="farms_not_created">
                                <div className="farms_not_created_inner">
                                    <div><img src={animation}></img></div>
                                    <div>   No Farms Created Yet !!!</div>
                                </div>
                             
                            </div>
                        
                        </div>
                 
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Farms)

