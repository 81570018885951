import React, { Component } from 'react'
import './Land.css'

import logo from '../assets/logo.svg'
import arrrowupw from '../assets/arrow_up.svg'
import hero_line from '../assets/hero_line.svg'
import hero_right from '../assets/hero_right.svg'
import sec3 from '../assets/sec3.svg'
import sec4 from '../assets/sec4.svg'
import square from '../assets/marquee_square.svg'
import minus from '../assets/minus.svg'
import plus from '../assets/plus.svg'
import konst from '../assets/konst.svg'
import doll_l from '../assets/doll_l.svg'
import doll_r from '../assets/doll_r.svg'

import Marquee from "react-fast-marquee";


import {
Link
} from "react-router-dom";



export default class Land extends Component {

    state={
        item1:false,
        item2:false,
        item3:false,
        item4:false
    }

    setitem1(){
        this.setState({item1:!this.state.item1})
    }
    setitem2() {
        this.setState({ item2: !this.state.item2 })
    }
    setitem3() {
        this.setState({ item3: !this.state.item3 })
    }
    setitem4() {
        this.setState({ item4: !this.state.item4 })
    }


    render() {
        return (
            <div className="land_main_container">
                <div className="land_main_container_inner">


                    <div className="land_header">
                        <div className="land_header_left">
                            <img src={logo} className="land_logo_main" />
                            <div className="land_header_left_text">Konstant</div>
                        </div>
                        <div className="land_header_right">
                          <Link to="/dashboard">  <div className="land_header_right_button">
                                <div>Dashboard</div>
                                <img src={arrrowupw} className="land_header_right_button_icon" />
                            </div></Link>
                        </div>
                    </div>


                    <div className="land_hero">
                        <div className="land_hero_inner">
                            <div>
                                <div className="land_hero_top">
                                    <img src={hero_line} className="land_hero_icon" />
                                    <div>Konstant Protocol</div>
                                </div>
                                <div className="land_hero_title">
                                    <div>ALGORITHMIC STABLE COIN WITH AN</div>
                                    <div>  INHERANT TWIST IN ITS</div>
                                    <div>  SUPPLY MECHANICS</div>
                                </div>
                                <div className="land_hero_caption">
                                    <div>Supply changes everyday proportional</div>
                                    <div>to all holders (Pro-Rata).</div>
                                </div>
    <Link to={{ pathname: "https://docs.konstant.tech" }} target="_blank" >
                                    <div className="land_hero_button_container">
                                    <div className="land_hero_button">
                                    <div>Learn more</div>
                                    <img src={arrrowupw} className="land_header_right_button_icon" />
                                        </div></div> </Link>
                            </div>
                            <div className="land_hero_right_image_container">
                                <img src={hero_right} className="land_hero_right_image" />
                            </div>
                            
                        </div>
                    </div>


                    <div className="land_section_2">
                        <div className="land_section_2_inner">
                            <div className="land_section_2_item1">
                                <div className="land_section_2_item_head">$0.30</div>
                                <div className="land_section_2_item_caption">Initital KUSD Price</div>
                            </div>
                            <div className="land_section_2_item2">
                                <div className="land_section_2_item_head">0.70</div>
                                <div className="land_section_2_item_caption">Initital Redemption Delta</div>
                            </div>
                            <div className="land_section_2_item3">
                                <div className="land_section_2_item_head">11,500,000</div>
                                <div className="land_section_2_item_caption">Initital Total Supply Of KUSD</div>
                            </div>
                        </div>
                    </div>

                    <div className="land_section_3">
                        <div className="land_section_3_inner">
                            <div>
                                <img src={sec3} className="land_section_3_image" />
                            </div>
                            <div>
                                <div className="land_section_3_right_title">
                                    ABOUT KUSD COIN
                                </div>
                                <div className="land_section_3_right_content">
                                    KUSD is like BUSD except it is much more reactive to demand and supply.
                                    KUSD is a cryptocurrency and financial building-block. Much like Bitcoin, it is algorithmic and uncollateralized. However unlike Bitcoin, KUSD can be used as a stablecoin.
                                </div>
                                <Link to={{ pathname: "https://bscscan.com/token/0x173F16f6DeBA17cfacFc4C9DC8F804C547B18c12" }} target="_blank" >
                                    <div className="land_hero_button">
                                        <div>View Contract</div>
                                        <img src={arrrowupw} className="land_header_right_button_icon" />
                                    </div>
                                </Link>
                                
                            </div>
                        </div>
                    </div>

                    <div className="land_marquee">
                        <hr></hr>
                        <Marquee>
                            <div className="land_marquee_flex">
                                <div>STABLECOIN OF THE FUTURE</div>
                                <img src={square} className="land_marquee_icon" />
                                <div>STABLECOIN OF THE FUTURE</div>
                                <img src={square} className="land_marquee_icon" />
                                <div>STABLECOIN OF THE FUTURE</div>
                                <img src={square} className="land_marquee_icon" />
                                <div>STABLECOIN OF THE FUTURE</div>
                                <img src={square} className="land_marquee_icon" />
                            </div>
                          
                        </Marquee>
                        <hr></hr>
                    </div>


                    <div className="land_sec_3">
                        <div className="land_sec_3_inner">
                            <div className="land_sec_3_item1">Fiat collateralized stablecoins
                                such as (USDT, USDC) rely on
                                centralized banking
                                partnerships.
                            </div>
                            <div className="land_sec_3_item2">Debt-marketplace derived
                                stablecoins such as (DAI)
                                cannot be sustained by free
                                market incentives and rely
                                on periodic bailouts.
                            </div>
                            <div className="land_sec_3_item3">KUSD is an independent financial
                                primitive that does not rely on
                                centralized collateral or
                                lenders of last resort.
                            </div>
                        </div>

                    </div>


                    <div className="land_sec_4">
                        <div className="land_sec_4_inner">
                            <div className="land_sec_4_left">
                                <div className="land_sec_4_left_title">HOW DOES THE PROTOCOL WORK ?</div>
                                <div className="land_sec_4_left_content">The Konstant protocol translates price-volatility into supply-volatility. This means the number of KUSD tokens in user wallets automatically increases or decreases based on price.

                                   <br></br><br></br> These supply adjustments are called "Redemptions" and they occur once each day. When the KUSD network grows you'll automatically have more tokens, when the AMPL network shrinks you'll automatically have fewer tokens, but the price per KUSD will tend to cycle around $1. This novel redemption mechanism is what allows KUSD to be used as a stablecoin.


                                </div>
                            </div>
                            <div>
                                <img src={sec4} className="land_sec4_image" />
                            </div>
                        </div>
                    </div>

                    <div className="land_sec_5">
                        <div className="land_sec_5_inner">
                            <div className="land_sec_5_item" onClick={()=>this.setitem1()}>
                                <hr></hr>
                                <div className="land_sec_5_item_no">01</div>
                                    <div className="land_Sec_5_item_flex">
                                        <div className="land_sec_5_item_title">The number of KUSD you own can change each day</div>
                                        <div><img src={this.state.item1 ? minus : plus} className="land_sec_5_icon" /></div>
                                    </div>
                                   { this.state.item1 && <div className="land_sec_5_item_content">The Konstant protocol automatically adjusts supply in response to demand. When price is high, wallet balances increase. When price is low, wallet balances decrease.
                                    </div> }
                                <hr></hr>
                            </div>
                            <div className="land_sec_5_item" onClick={() => this.setitem2()}>
                                <div className="land_sec_5_item_no">02</div>
                                <div className="land_Sec_5_item_flex">
                                    <div className="land_sec_5_item_title">You can never be diluted by supply inflation</div>
                                    <div><img src={this.state.item2 ? minus : plus} className="land_sec_5_icon" /></div>
                                </div>
                               {this.state.item2 && <div className="land_sec_5_item_content">KUSD is non-dilutive. Supply adjustments are applied universally and proportionally across every wallet’s balance. This means your percent ownership of the network remains fixed.
                                </div> }
                                <hr></hr>
                            </div>
                            <div className="land_sec_5_item" onClick={() => this.setitem3()}>
                                <div className="land_sec_5_item_no">03</div>
                                <div className="land_Sec_5_item_flex">
                                    <div className="land_sec_5_item_title">This Solves The Diversification Problem  </div>
                                    <div><img src={this.state.item3 ? minus : plus} className="land_sec_5_icon" /></div>
                                </div>
                               {this.state.item3 && <div className="land_sec_5_item_content">Today's cryptocurrenices are dangerously correlated. Konstant's unique incentives allow it to decouple from Bitcoin's price pattern. This reduces systematic risk by adding diversity to a homogeneous ecosystem.
                                </div> }
                                <hr></hr>
                            </div>
                            <div className="land_sec_5_item" onClick={() => this.setitem4()}>
                                <div className="land_sec_5_item_no">04</div>
                                <div className="land_Sec_5_item_flex">
                                    <div className="land_sec_5_item_title">This Solves The Inelasticity Problem</div>
                                    <img src={this.state.item4 ? minus : plus} className="land_sec_5_icon" />
                                </div>
                               {this.state.item4 && <div className="land_sec_5_item_content">Like precious metals, today's fixed supply cryptocurrencies are vulnerable to sudden shocks in demand and cannot be used to denominate complex contracts. As a result, sophisticated economies cannot be built upon them.KUSD is the simplest direct solution to the supply inelasticity problem.
                                </div> }
                                <hr></hr>
                            </div>
                        </div>
                    </div>


                    <div className="land_sec_6">
                        <div className="land_sec_6_inner">
                            <div className="land_sec_6_item">
                                <div className="land_sec_6_title">PROTOCOL GOVERNANCE AND TOKEN</div>
                                <div className="land_sec_6_content">KONST is a new governance token that completes the Konstant ecosystem by putting control of the protocol in the hands of the community. Using the KONST token, holders will be able to vote on changes to the protocol. Together, $KUSD + $KONST mark a major milestone in the protocol’s journey towards becoming fully decentralized.</div>
                                <div className="land_sec_6_icon_holder"><img src={konst} className="land_sec_6_icon" /></div>
                            </div>

                        </div>
                    </div>

                    <div className="land_sec_7">
                        <div className="land_sec_7_inner">
                            <div><img src={doll_l} className="land_sec_7_left_icon" /></div>
                            <div className="land_sec_7_content">
                                <div className="land_sec_7_title">IF YOU ARE CONVINCED KONSTANT
                                    STABLECOIN IS WORTH HODLING</div>
                                    <div>
                                    <div className="land_hero_button_black">
                                        <div>Get Now</div>
                                        <img src={arrrowupw} className="land_header_right_button_icon" />
                                    </div>
                                    </div>
                            </div>
                            <div><img src={doll_r} className="land_sec_7_right_icon" /></div>
                        </div>
                    </div>


                    <div className="land_sec_8">
                        <div className="land_sec_8_inner">
                            <Link to={{ pathname: "https://docs.konstant.tech" }} target="_blank" >
   <div>Documentation</div> </Link>
                            <Link to="/dashboard"><div>Dashboard</div></Link>
                            <div>Twitter</div>
                            <div>Discord</div>
                            <div>Telegram</div>
                        </div>
                    </div>

                </div>
               
            </div>
        )
    }
}



