import React, { Component } from 'react'
import './Dashboard.css';
import Header from './Header'
import { Line, defaults} from 'react-chartjs-2'
import { withRouter } from 'react-router-dom';


import { motion } from 'framer-motion'
import  KUSD from '../contracts/build/contracts/KUSD.json';


import Web3 from 'web3'

var web3 = new Web3('http://localhost:7545');

var myContract = new web3.eth.Contract(KUSD.abi, '0x259ba3e44a599F2B7dCF6B5a8499f9a3d0E8Ffda');




defaults.font.family="'dmm'"
defaults.font.size = 8

class Dashboard extends Component {

    state={
        timer:0,
        timerstring:'00:00:00',
        chart:50,
        oracleRate:0.00,
        totalsupply:0,
        pricechart:{
            high:0,
            low:0,
            avg:0,
            median:0
        },
        supplychart: {
            high: 0,
            low: 0,
            avg: 0,
            median: 0
        },
        deltachart: {
            high: 0,
            low: 0,
            avg: 0,
            median: 0
        }

    }

    componentDidMount() {
        myContract.methods.next_epoch_time().call()
            .then(e=>{
                let countDownDate = new Date(e*1000).getTime();
                let now = new Date().getTime();
                let day = 24 * 60 * 60 * 1000
                let distance = countDownDate - now;

                let chart = (distance/day)*100

                let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                this.setState({timer:e,timerstring:hours+':'+minutes+':'+seconds,chart:chart})
            });
        
        this.intervalId = setInterval(this.timer.bind(this), 1000);
        myContract.getPastEvents('LogOracleRate', { fromBlock: 0, toBlock: 'latest' }).then(
           e=> {
            if(e.length > 0){
                this.logoraclerate(e)
            }   
                }
        )
        myContract.methods.totalSupply().call().then(
            e=> this.setState({totalsupply:e})
        )
        
        
    }

    timer() {
        let timer = this.state.timer
        let countDownDate = new Date(timer * 1000).getTime();
        let now = new Date().getTime();

        let day = 24*60*60*1000


        let distance = countDownDate - now;

        let chart = ((distance / day) * 100).toString().slice(0, 2);

      

        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (seconds.toString().length == 1){
            seconds = '0'+ seconds
        } else if (minutes.toString().length == 1){
            minutes = '0' + minutes
        } else if (hours.toString().length == 1){
            hours = '0' + hours
        }
      
        this.setState({
            timer: timer, timerstring: hours + ':' + minutes + ':' + seconds,chart:chart
        })
        if (hours == 0 && minutes == 0 && seconds == 0) {
            clearInterval(this.intervalId);
        }
    }
  

    renderline = canvas =>{
        let data = this.state.data
        let ctx = canvas.getContext("2d")
        let gradient = ctx.createLinearGradient(0, 0, 0, 200)
        gradient.addColorStop(0, '#f2cbc5d4')
        gradient.addColorStop(1, '#fafafa9e')

        data.datasets[1].backgroundColor = gradient
    

        return data
    }

    rendersupplychart = canvas =>{
        let data = {
            labels: [],
            datasets: [
                {
                    label: 'Target Price',
                    borderColor: 'black',
                    borderDash: [3, 5],
                    borderWidth: 0.5,
                    backgroundColor: "rgba(27,33,34,1)",
                    data: []
                },

                {
                    label: 'Price',
                    data: [],
                    borderWidth: 0.7,
                    borderColor: 'black',
                    fill: 'start'
                }

            ]
        };

        myContract.getPastEvents('LogTotalSupply', { fromBlock: 0, toBlock: 'latest' }).then(
            e => {
                let arr = e
                let dataarr = []
                let refer = []
                let labelarr = []
                arr.map((i) => {
                    dataarr.push(Math.ceil(i.returnValues.TotalSupply / 1e18))
                    labelarr.push(new Date(i.returnValues.Time * 1000).getDate() + '/' + new Date(i.returnValues.Time * 1000).getMonth())
                })

                data.labels = labelarr
      
                data.datasets[1].data = dataarr

                let darr = data.datasets[1].data
                darr.sort(function (a, b) {
                    return a - b;
                });

                let high = darr[0]
                let low = darr[darr.length - 1]
                let sum = darr.reduce(function (a, b) { return Number(a) + Number(b); }, 0)
                let avg = (sum / darr.length)

                let median = darr[Math.ceil(darr.length / 2)]
                if (median == undefined) {
                    median = avg
                }


                this.setState({ supplychart: { high: high, low: low, avg: avg, median: median } })
            }
        )
        let ctx = canvas.getContext("2d")
        let gradient = ctx.createLinearGradient(0, 0, 0, 200)
        gradient.addColorStop(0, '#f2cbc5d4')
        gradient.addColorStop(1, '#fafafa9e')

        data.datasets[1].backgroundColor = gradient



        return data
    }

    renderdeltachart = canvas =>{
        let data = {
            labels: [],
            datasets: [
                {
                    label: 'Target Price',
                    borderColor: 'black',
                    borderDash: [3, 5],
                    borderWidth: 0.5,
                    backgroundColor: "rgba(27,33,34,1)",
                    data: []
                },

                {
                    label: 'Price',
                    data: [],
                    borderWidth: 0.7,
                    borderColor: 'black',
                    fill: 'start'
                }

            ]
        };
        myContract.getPastEvents('LogOracleRate', { fromBlock: 0, toBlock: 'latest' }).then(
            e => {
                let arr = e
                let dataarr = []
                let refer = []
                let labelarr = []
                arr.map((i) => {
                    dataarr.push(((i.returnValues.OracleRate - 105) / 100).toFixed(3))
                    labelarr.push(new Date(i.returnValues.Time * 1000).getDate() + '/' + new Date(i.returnValues.Time * 1000).getMonth())
                    refer.push(0.000)
                })

                data.labels = labelarr
                data.datasets[0].data = refer
                data.datasets[1].data = dataarr

                let darr = data.datasets[1].data
                darr.sort(function (a, b) {
                    return a - b;
                });

                let high = darr[0]
                let low = darr[darr.length - 1]
                let sum = darr.reduce(function (a, b) { return Number(a) + Number(b); }, 0)
                let avg = (sum / darr.length).toFixed(3)

                let median = darr[Math.ceil(darr.length / 2)]
                if (median == undefined) {
                    median = avg
                }


                this.setState({ deltachart: { high: high, low: low, avg: avg, median: median } })
            }
        )
        let ctx = canvas.getContext("2d")
        let gradient = ctx.createLinearGradient(0, 0, 0, 200)
        gradient.addColorStop(0, '#f2cbc5d4')
        gradient.addColorStop(1, '#fafafa9e')

        data.datasets[1].backgroundColor = gradient



        return data
    }

    renderpricechart = canvas =>{
        let data = {
            labels: [],
            datasets: [
                {
                    label: 'Target Price',
                    borderColor: 'black',
                    borderDash: [3, 5],
                    borderWidth: 0.5,
                    backgroundColor: "rgba(27,33,34,1)",
                    data: []
                },

                {
                    label:'Price',
                    data: [],
                    borderWidth: 0.7,
                    borderColor: 'black',
                    fill:'start'
                }
                
            ]
            };
        myContract.getPastEvents('LogOracleRate', { fromBlock: 0, toBlock: 'latest' }).then(
            e => {
               let arr = e
               let dataarr = []
               let refer = []
               let labelarr = []
               arr.map((i)=> {
                   dataarr.push((i.returnValues.OracleRate/100).toFixed(3))
                   labelarr.push(new Date(i.returnValues.Time * 1000).getDate() + '/' + new Date(i.returnValues.Time * 1000).getMonth())
                   refer.push(1.050)
                })
                
                data.labels = labelarr
                data.datasets[0].data = refer
                data.datasets[1].data = dataarr

                let darr = data.datasets[1].data
                darr.sort(function (a, b) {
                    return a - b;
                });

                let high = darr[0]
                let low = darr[darr.length - 1]
                let sum = darr.reduce(function (a, b) { return Number(a) + Number(b); }, 0)
                let avg = (sum / darr.length).toFixed(3)
               
                let median = darr[Math.ceil(darr.length / 2)]
                if(median == undefined){
                    median = avg
                }


                this.setState({ pricechart: { high: high, low: low, avg: avg, median: median } })
            }
        )
        let ctx = canvas.getContext("2d")
        let gradient = ctx.createLinearGradient(0, 0, 0, 200)
        gradient.addColorStop(0, '#f2cbc5d4')
        gradient.addColorStop(1, '#fafafa9e')

        data.datasets[1].backgroundColor = gradient
        


        return data
    }

    logoraclerate = (event)=>{
        let latest = event[event.length - 1]
        this.setState({ oracleRate: latest.returnValues.OracleRate})
    }

 

    render() {
       

        return (
          <div>
                <Header currentpage={this.props.location.pathname}/>
                <div className="dasboard_container">
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 0.5 }} className="dasboard_main_area">
                        <div className="dasboard_timer_container">
                            <div className="dashboard_timer_left">
                                Time Until Next Epoch
                            </div>
                            <div className="dashboard_timer_chart_area">
                                <div className="dasboard_timer_chart_holder">
                                    <div className="dasboard_timer_chart_pink" style={{width:`${100}%`}}></div>
                                    <div className="dasboard_timer_chart_black" style={{width:`${0}%`}}></div>
                                </div>
                            </div>
                            <div className="dashboard_timer_counter">
                                <div>00:00:00</div>
                            </div>
                        </div>
                        <div className="dashboard_card_flex">
                            <div className="dashboard_card_glass glass_1">
                               <div className="dasboard_card_heading">Oracle Rate (TWAP)</div>
                                <div className="dasboard_card_content">$0.070</div>
                            </div>
                            <div className="dashboard_card_glass glass_2">
                                <div className="dasboard_card_heading">Current Target Price</div>
                                <div className="dasboard_card_content">$1.000</div>
                            </div>
                            <div className="dashboard_card_glass glass_3">
                                <div className="dasboard_card_heading">Redemption Delta Value</div>
                                <div className="dasboard_card_content">0.930</div>
                            </div>
                            <div className="dashboard_card_glass glass_4">
                                <div className="dasboard_card_heading">Total Circulation Supply</div>
                                <div className="dasboard_card_content">11,500,000</div>
                            </div>
                            <div className="dashboard_card_glass glass_5">
                                <div className="dasboard_card_heading">Outstanding Treasury Debt</div>
                                <div className="dasboard_card_content">0</div>
                            </div>
                            <div className="dashboard_card_glass glass_6">
                                <div className="dasboard_card_heading">Current Market Cap</div>
                                <div className="dasboard_card_content">11,500,000</div>
                            </div>
                            <div className="dashboard_chart_card_glass">
                                <div>Price Chart</div>
                                <div className="dashboard_chart_card_buttons">
                                    <div className="dashboard_chart_card_buttons_item">30D</div>
                                    <div className="dashboard_chart_card_buttons_item">60D</div>
                                    <div className="dashboard_chart_card_buttons_item">90D</div>
                                </div>
                                <div className="dashboard_chart_card_area">
                                    <Line
                                        options={{
                                            animation: {
                                                duration: 0
                                            },
                                            responsive: true,
                                            elements: {
                                                line: {
                                                    tension: 0
                                                },
                                                point: {
                                                    radius: 1.5
                                                }
                                            },
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                }
                                            },

                                            scales: {
                                                x: {
                                                    ticks: {
                                            
                                                        callback: function (value, index, values) {

                                                            return this.getLabelForValue(value);


                                                        },
                                                        autoSkip: true
                                                    },

                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    
                                                    grid: {
                                                        display: false
                                                    }
                                                }
                                            }


                                        }}
                                        data={this.renderpricechart}
                             
                                    />
                                </div>
                                <div className="dashboard_chart_card_stats">
                                    <div className="dashboard_chart_card_item">High - {this.state.pricechart.high}</div>
                                    <div className="dashboard_chart_card_item">Low - {this.state.pricechart.low}</div>
                                </div>
                                <div className="dashboard_chart_card_stats">
                                    <div className="dashboard_chart_card_item">Avg. - {this.state.pricechart.avg}</div>
                                    <div className="dashboard_chart_card_item">Median - {this.state.pricechart.median}</div>
                                </div>
                            </div>
                            <div className="dashboard_chart_card_glass">
                                <div>Supply Chart</div>
                                <div className="dashboard_chart_card_buttons">
                                    <div className="dashboard_chart_card_buttons_item">30D</div>
                                    <div className="dashboard_chart_card_buttons_item">60D</div>
                                    <div className="dashboard_chart_card_buttons_item">90D</div>
                                </div>
                                <div className="dashboard_chart_card_area">
                                    <Line
                                        options={{
                                            animation: {
                                                duration: 0
                                            },
                                            responsive: true,
                                            elements: {
                                                line: {
                                                    tension: 0
                                                },
                                                point: {
                                                    radius: 1.5
                                                }
                                            },
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                }
                                            },

                                            scales: {
                                                x: {
                                                    ticks: {
                                                        // Include a dollar sign in the ticks
                                                        callback: function (value, index, values) {

                                                            return this.getLabelForValue(value);


                                                        },
                                                        autoSkip: true
                                                    },

                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    ticks: {
                                                        // Include a dollar sign in the ticks
                                                        callback: function (value, index, values) {
                                                            
                                                            let val = this.getLabelForValue(value)
                                                            let spl = val.split(",")
                                                            console.log(spl[0])
                                                            return spl[0]+'M';


                                                        },
                                                        autoSkip: true
                                                    },
                                                    grid: {
                                                        display: false
                                                    }
                                                }
                                            }


                                        }}
                                        data={this.rendersupplychart}
                                     
                                    />
                                </div>
                                <div className="dashboard_chart_card_stats">
                                    <div className="dashboard_chart_card_item">High - {this.state.supplychart.high}</div>
                                    <div className="dashboard_chart_card_item">Low - {this.state.supplychart.low}</div>
                                </div>
                                <div className="dashboard_chart_card_stats">
                                    <div className="dashboard_chart_card_item">Avg. - {this.state.supplychart.avg}</div>
                                    <div className="dashboard_chart_card_item">Median - {this.state.supplychart.median}</div>
                                </div>
                            </div>
                            <div className="dashboard_chart_card_glass">
                                <div>Redemption Delta Chart</div>
                                <div className="dashboard_chart_card_buttons">
                                    <div className="dashboard_chart_card_buttons_item">30D</div>
                                    <div className="dashboard_chart_card_buttons_item">60D</div>
                                    <div className="dashboard_chart_card_buttons_item">90D</div>
                                </div>
                                <div className="dashboard_chart_card_area">
                                    <Line
                                        options={{
                                            animation: {
                                                duration: 0
                                            },
                                            responsive: true,
                                            elements: {
                                                line: {
                                                    tension: 0
                                                },
                                                point: {
                                                    radius: 1.5
                                                }
                                            },
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                }
                                            },

                                            scales: {
                                                x: {
                                                    ticks: {
                                                        // Include a dollar sign in the ticks
                                                        callback: function (value, index, values) {

                                                            return this.getLabelForValue(value);


                                                        },
                                                        autoSkip: true
                                                    },

                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    grid: {
                                                        display: false
                                                    }
                                                }
                                            }


                                        }}
                                        data={this.renderdeltachart}
              
                                    />
                                </div>
                                <div className="dashboard_chart_card_stats">
                                    <div className="dashboard_chart_card_item">High - {this.state.deltachart.high}</div>
                                    <div className="dashboard_chart_card_item">Low - {this.state.deltachart.low}</div>
                                </div>
                                <div className="dashboard_chart_card_stats">
                                    <div className="dashboard_chart_card_item">Avg. - {this.state.deltachart.avg}</div>
                                    <div className="dashboard_chart_card_item">Median - {this.state.deltachart.median}</div>
                                </div>
                            </div>

                        </div>
                
                       
                       
                    </motion.div>
                </div>
                
              
          </div>
        )
    }
}

export default withRouter(Dashboard)